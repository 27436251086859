$( document ).ready(function() {

    $(".js-search-main-btn").on("click" , function(){ 
        $(".js-search-main").addClass("is-open")
    });

    

    if ($(window).width() > 961) {
        $(document).mouseup(function (e){ // событие клика по веб-документу
            var div = $(".js-search-main"); // тут указываем ID элемента
            if (!div.is(e.target) // если клик был не по нашему блоку
                && div.has(e.target).length === 0) { // и не по его дочерним элементам
                div.removeClass("is-open");
            }
        });
    }

    $(".js-hamburger").on( "click", function(event) {
        $(".js-menu-adaptive").toggleClass("is-open");
        $(".js-hamburger").toggleClass("is-active");
    });

    $(".js-tab-item").on("click" , function(){ 
        let tabNumber = $(this).data("tab");

        $(".js-tab-item").removeClass("is-active");
        $(this).addClass("is-active");

        $(".js-tab-block").removeClass("is-active");
        $(".js-tab-block[data-tab="+tabNumber+"]").addClass("is-active");
    });


    $(".js-accordion-top").on("click" , function(){ 
        let parents = $(this).parents(".js-accordion");

        $(this).toggleClass("is-active");
        $(parents).find(".js-accordion-container").slideToggle(300, function(){});
    });

    if ($(window).width() > 961) {
        $(".js-sticky").stick_in_parent({
            offset_top: 40
        });
    }
 

    $( ".js-spinner" ).spinner({
        min: 0
    });


    $(window).scroll(function() {
        let scrollTop = $(window).scrollTop();

        $(".js-anchor-block").each(function( index , elem ) {
            let offsetItem = $(elem).offset().top,
                dataItem = $(this).data("anchor");


            if ((offsetItem - 120) < scrollTop) {
                $(".js-anchor-item").removeClass("is-active");
                $(".js-anchor-item[data-anchor="+dataItem+"]").addClass("is-active");
            }
        });
    });


    $('.js-anchor-item').on("click" , function(){

        let anchor = $(this).data('anchor');  

        $('html, body').animate({           
            scrollTop:   $('.js-anchor-block[data-anchor='+anchor+']').offset().top - 100 
        }, 600);     

    });


    $('.js-main-banner-slider').slick({
       /* autoplay: true,
        autoplaySpeed: 2000,*/
        arrows: false,
        fade: true,dots: true,
        cssEase: 'linear'
      
    });

    $(".js-distributor").slick({
        slidesToShow: 8,
        slidesToScroll: 1,
        infinite: false,
        
        arrows: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg width='6' height='10' viewBox='0 0 6 10' class='svg-icon'><use xlink:href='#svg-arrow-slider'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg width='6' height='10' viewBox='0 0 6 10' class='svg-icon'><use xlink:href='#svg-arrow-slider'></use></svg>\
        </button>" ,
        responsive: [
            {
              breakpoint: 1279,

                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 900,
  
                  settings: {
                      slidesToShow: 6,
                  }
              },
              {
                  breakpoint: 900,
    
                    settings: {
                        slidesToShow: 4,
                    }
                }
                ,
              {
                  breakpoint: 640,
    
                    settings: {
                        slidesToShow: 2,
                    }
                }
        ]
    });

    $('.js-slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.js-slider-nav',
        responsive: [
            {
              breakpoint: 961,

                settings: {
                    dots: true,
                    arrows: false
                }
            }
        ]
    });

    $('.js-slider-nav').slick({
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.js-slider-for',
        focusOnSelect: true,
        responsive: [
            {
              breakpoint: 1279,

                settings: {
                    vertical: false,
                    verticalSwiping: false
                }
            }
        ]
    });

    if ($('#map2').length) {
        var init = function init() {
            var myMap = new ymaps.Map("map2", {
                center: [ 55.904722, 38.493999],
                zoom: 16
            });

            var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
                iconLayout: 'default#image',
                iconImageHref: './images/common/marker.png',
                iconImageSize: [50, 70],
                iconImageOffset: [-25, -70]
            });


            myMap.controls.remove('zoomControl');
            myMap.behaviors.disable('scrollZoom');
            myMap.behaviors.disable('drag');
            myMap.geoObjects.add(myPlacemark);
        };

        ymaps.ready(init);
    }


    if ($('#map').length) {
        var init = function init() {
            var myMap = new ymaps.Map("map", {
                center: [55.781858, 37.499920],
                zoom: 16
            });

            var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
                iconLayout: 'default#image',
                iconImageHref: './images/common/marker.png',
                iconImageSize: [50, 70],
                iconImageOffset: [-25, -70]
            });


            myMap.controls.remove('zoomControl');
            myMap.behaviors.disable('scrollZoom');
            myMap.behaviors.disable('drag');
            myMap.geoObjects.add(myPlacemark);
        };

        ymaps.ready(init);
    }


    $(".js-filter-top").on("click", function () {
        $(this).toggleClass("is-active");
        $(this).next(".js-filter-body").slideToggle(300, function () {});
    });

    $(".js-filter-header").on("click", function () {
        $(this).toggleClass("is-active");
        $(this).next(".js-filter-container").slideToggle(300, function () {});
    });


    $(".js-sub-btn").on( "click", function(event) {
  
        if ($(".js-sub").find("input[type='text']").val() == "") {
            $(".js-sub").addClass("is-error");
        } else {
            $(".js-sub").removeClass("is-error");
        }
        
        return false;
    });



});